  import React from 'react'
  import Form2 from '../Components/Form2'

  function StudentForm() {
    return (
      <>
          <div className="bg-transparent flex justify-center items-center mx-0 my-20 lg:mx-10 lg:my-28">
            <Form2 />
          </div>
      </>
    )
  }

  export default StudentForm
